export function isNil<T>(input: T | undefined | null): input is null | undefined {
  return input == null;
}

export function isUndefined<T>(input: T | undefined | null): input is undefined {
  return input === undefined;
}

export type NullablePartial<T> = {
  [P in keyof T]?: T[P] | null;
};

export type Optional<T, K extends keyof T> = Omit<T, K> & NullablePartial<Pick<T, K>>;
