import * as React from "react";

export const SadFace = () => (
  <svg width="35" height="35" viewBox="0 0 90 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.1841 89C69.7607 89 89.6841 69.0767 89.6841 44.5C89.6841 19.9233 69.7607 0
      45.1841 0C20.6074 0 0.684082 19.9233 0.684082 44.5C0.684082 69.0767 20.6074 89 45.1841 89Z"
      fill="#BF1824"
    />
    <mask id="mask0_117_34939" maskUnits="userSpaceOnUse" x="13" y="18" width="65" height="54">
      <path d="M77.1081 18.3804H13.2603V71.5869H77.1081V18.3804Z" fill="white" />
    </mask>
    <g mask="url(#mask0_117_34939)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.2809 66.7498C33.382 66.7498 23.7081 57.0759 23.7081 45.1769C23.7081 44.403 23.7081 43.6291
        23.8048 42.8552L23.9983 40.7269L21.3863 38.115L13.2603 45.8541C13.357 48.1759 13.6472 50.4018 14.1309
        52.5291L18.4842 48.0791C19.9353 61.5268 31.3505 71.9737 45.1842 71.9737C55.5353 71.9737 64.532 66.0726
        68.982 57.4628L64.3385 55.0443C60.8559 62.0105 53.6005 66.7498 45.2809 66.7498Z"
        fill="#FEFCFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.2812 23.6045C57.1801 23.6045 66.854 33.2784 66.854 45.1774C66.854 45.9513 66.854 46.7242
        66.7572 47.4991L66.5638 49.6264L69.1757 52.2393L77.3018 44.5002C77.2051 42.1784 76.9149 39.9534 76.4312
        37.8252L72.0779 42.2752C70.6268 28.8284 59.2116 18.3806 45.3779 18.3806C35.0268 18.3806 26.0301 24.2817
        21.5801 32.8915L26.2236 35.31C29.7062 28.3447 36.9616 23.6045 45.2812 23.6045Z"
        fill="#FEFCFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0088 36.3568V36.7438C35.2023 38.6786 36.9436 40.1297 38.8784 39.9362C40.8131 39.7427 42.2642
        38.0014 42.0707 36.0666C41.974 35.0025 41.3945 34.1318 40.6197 33.5514L35.0088 36.3568Z"
        fill="#FEFCFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.4909 35.8729C48.2975 37.8077 49.7486 39.549 51.6833 39.7425C53.6181 39.936 55.3594 38.4849 55.5529
        36.5501V36.1632L49.8443 33.4545C49.0714 33.9382 48.5877 34.9056 48.4909 35.8729Z"
        fill="#FEFCFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.3409 57.2215C58.5344 57.415 58.8246 57.415 59.1148 57.2215C59.3083 57.1238 59.4051 57.028 59.4051
        56.8346C59.4051 56.7378 59.3083 56.5444 59.1148 56.2541C57.2768 53.8357 55.2453 51.9009 53.0203 50.4498
        C50.5051 48.901 47.8931 48.1281 45.2811 48.1281C42.6692 48.1281 40.0572 48.901 37.542 50.4498C35.2203
        51.9009 33.1887 53.738 31.4474 56.2541C31.254 56.4476 31.1572 56.6401 31.1572 56.8346C31.1572 57.028
        31.254 57.1238 31.4474 57.2215C31.8344 57.415 32.0279 57.415 32.2214 57.2215C34.1561 55.0933 36.0909
        53.4487 37.929 52.4813C40.0572 51.3195 42.5724 50.74 45.3779 50.74C48.1833 50.74 50.6985 51.3195 52.8268
        52.4813C54.4714 53.4487 56.4061 55.0933 58.3409 57.2215Z"
        fill="#FEFCFA"
      />
    </g>
  </svg>
);
