import {Empty} from "antd";
import React from "react";

type EmptyTableProps = {
  loading?: boolean;
};

const EmptyTable: React.FC<EmptyTableProps> = (props) => {
  const {loading} = props;

  return loading ? <div style={{height: 150}} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
};

export default EmptyTable;
