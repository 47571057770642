import {ReactNode, createContext, useContext} from "react";
import type {Context} from "react";
import {createStore, StoreApi, useStore} from "zustand";

import {CRUDRecord} from "@/app/_components/crud/types";
import {UseTableColumnFilteredInfo} from "@/hooks/use-table-column-props";
import {ConnectionOrderDirection} from "@/shared/codegen/types";
import {isEmpty} from "@/shared/utils/object";

type State<R extends CRUDRecord = CRUDRecord> = {
  inputSearchText?: string;
  searchText?: string;
  filteredInfo: UseTableColumnFilteredInfo;
  selection?: R;
  recordsOffset: number;
  tablePageSize: number | undefined;
  orderBy: {field: string; direction: ConnectionOrderDirection} | undefined;
};

type Action<R extends CRUDRecord = CRUDRecord> = {
  setInputSearchText: (value: string | undefined) => void;
  setSearchText: (value: string | undefined) => void;
  setFilteredInfo: (value: UseTableColumnFilteredInfo) => void;
  setSelection: (value: R | undefined) => void;
  setRecordsOffset: (value: number) => void;
  setTablePageSize: (value: number) => void;
  setOrderBy: (orderInfo: {field: string; direction: ConnectionOrderDirection}) => void;

  reset: () => void;
};

type StoreType = State & Action;

const initialState: State = {
  inputSearchText: undefined,
  searchText: undefined,
  filteredInfo: {},
  selection: undefined,
  recordsOffset: 0,
  tablePageSize: undefined,
  orderBy: undefined,
};

const createCRUDStore = () =>
  createStore<StoreType>((set) => ({
    ...initialState,

    setInputSearchText: (value) => {
      const searchText = value;

      set({inputSearchText: !isEmpty(value) ? value : undefined, searchText: searchText});
    },
    setSearchText: (value) => set({searchText: !isEmpty(value) ? value : undefined}),
    setFilteredInfo: (value) => set({filteredInfo: value}),
    setSelection: (value) => set({selection: value}),
    setRecordsOffset: (value) => set({recordsOffset: Number(value)}),
    setTablePageSize: (value) => set({tablePageSize: Number(value)}),
    setOrderBy: (value) => set({orderBy: value}),

    reset: () => set(initialState, true),
  }));

const Context: Context<StoreApi<StoreType> | null> = createContext<ReturnType<typeof createCRUDStore> | null>(null);

const Provider = ({children}: {children: ReactNode}) => {
  const store = createCRUDStore();
  return <Context.Provider value={store}>{children}</Context.Provider>;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/display-name
const withCRUD = (Component) => () => (
  <Provider>
    <Component />
  </Provider>
);

const useCRUD = <R extends CRUDRecord>() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const store: StoreApi<StoreType<R>> | null = useContext(Context);
  if (store === null)
    throw new Error("Use withCRUD HOC to wrap your page or component ( export default withCRUD(MyPage); )");

  return useStore(store);
};

export {withCRUD, useCRUD};
