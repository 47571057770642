"use client";

import {EyeOutlined, MenuOutlined} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import type {ColumnsType} from "antd/lib/table";
import React, {useCallback, useMemo, useState} from "react";

import {useCRUD, withCRUD} from "@/app/_components/crud/context";
import {useTableColumnProps} from "@/hooks/use-table-column-props";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import {TapModule} from "@/modules/tap";
import {TapReportStatus} from "@/shared/codegen/types";
import {Breakpoints} from "@/utils/breakpoints";

import {PropertiesRecord} from "./_components/crud/types";
import DefaultPage from "./_components/default-page/page";

type Record = PropertiesRecord;
type FilterType = TapReportStatus.Pending | TapReportStatus.Approved | TapReportStatus.Rejected;

const Home = () => {
  const {width: windowWidth} = useWindowDimensions();
  const queryClient = useQueryClient();

  const {searchText, recordsOffset, tablePageSize, orderBy} = useCRUD();

  const [filter, setFilter] = useState<FilterType | null>(null);

  const approveMutation = useMutation({mutationFn: TapModule.approveReport});
  const rejectMutation = useMutation({mutationFn: TapModule.rejectReport});

  const placeColumnProps = useTableColumnProps<Record>({
    dataIndex: ["business", "name"],
    dataType: "string",
    sort: {
      defaultSortOrder: "ascend",
    },
  });
  const locationColumnProps = useTableColumnProps<Record>({
    dataIndex: ["business", "address"],
    dataType: "string",
    sort: {
      defaultSortOrder: "ascend",
    },
  });
  const dateColumnProps = useTableColumnProps<Record>({
    dataIndex: "lastReportDate",
    dataType: "date",
    sort: {
      defaultSortOrder: "ascend",
    },
  });
  const userColumnProps = useTableColumnProps<Record>({
    dataIndex: ["tappedByUser", "profile", "username"],
    dataType: "string",
    sort: {
      defaultSortOrder: "ascend",
    },
  });
  const numberColumnProps = useTableColumnProps<Record>({
    dataIndex: "reportCount",
    dataType: "number",
    sort: {
      defaultSortOrder: "ascend",
    },
  });
  const statusColumnProps = useTableColumnProps<Record>({
    dataIndex: "reportStatus",
    dataType: "string",
    sort: {
      defaultSortOrder: "ascend",
    },
  });

  const columns: ColumnsType<Record> = [
    {
      ...placeColumnProps,
      key: "place",
      title: "Place",
      width: 160,
    },
    {
      ...locationColumnProps,
      key: "location",
      title: "Location",
      width: 160,
    },
    {
      ...dateColumnProps,
      key: "date",
      title: "Last Reported Date",
      width: 160,
    },
    {
      ...userColumnProps,
      key: "user",
      title: "User reported",
      width: 160,
      fixed: windowWidth <= Breakpoints.SM ? undefined : "left",
    },
    {
      ...numberColumnProps,
      key: "number",
      title: "Reports",
      width: 80,
    },
    {
      ...statusColumnProps,
      key: "status",
      title: "Status",
      width: 80,
    },
    {
      key: "view",
      title: "View",
      width: 40,
      render: () => <EyeOutlined style={{fontSize: "16px", color: "#726F6F"}} />,
    },
  ];

  const queryArgs = useMemo(
    () => ({
      searchText,
      offset: recordsOffset,
      pageSize: tablePageSize,
      orderBy,
      filter,
    }),
    [searchText, recordsOffset, tablePageSize, orderBy, filter]
  );

  const fetchTaps = useCallback(() => {
    return TapModule.fetchTaps({
      filter: {
        reportedOnly: true,
        reportStatus: queryArgs.filter,
      },
      first: queryArgs.pageSize,
      offset: queryArgs.offset,
      orderBy: queryArgs.orderBy,
    });
  }, [queryArgs]);

  const baseQueryKey = "taps";
  const queryKey = useMemo(() => [baseQueryKey, queryArgs], [queryArgs]);
  const queryResult = useQuery({queryKey, queryFn: fetchTaps});

  const handleInvalidateCache = () => queryClient.invalidateQueries({queryKey: [baseQueryKey]});

  const approveReport = async (id: string) => {
    return approveMutation.mutateAsync({tapId: id});
  };

  const rejectReport = async (id: string) => {
    return rejectMutation.mutateAsync({tapId: id});
  };

  return (
    <DefaultPage
      identifier="home"
      title={
        <>
          <h1>
            <MenuOutlined style={{marginRight: 8, marginBottom: 12, color: "#3057D1", fontSize: "24px"}} /> Home
          </h1>
        </>
      }
      table={{
        columns: columns,
      }}
      queryResult={queryResult}
      onInvalidateCache={handleInvalidateCache}
      filter={filter}
      setFilter={setFilter}
      approveReport={approveReport}
      rejectReport={rejectReport}
    />
  );
};

export default withCRUD(Home);
