import {isNil} from "./nil";

type EmptyArray = never[];
type EmptyObject = Record<string, never>;
type EmptyString = "";

type Empty = EmptyArray | EmptyObject | EmptyString;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Bottom<T> = T extends string ? EmptyString : T extends any[] ? EmptyArray : T extends object ? EmptyObject : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty<T extends string | any[] | object | Record<string, unknown>>(
  value: T | Empty | null | undefined
): value is Bottom<T> | null | undefined {
  if (isNil(value)) {
    return true;
  }

  if (Array.isArray(value) || isString(value)) {
    return value.length === 0;
  }

  if (isObject(value) && !isDate(value)) {
    return Object.keys(value).length === 0;
  }

  return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: any): value is Record<string, unknown> | object {
  return !isNil(value) && (typeof value === "object" || typeof value === "function") && !Array.isArray(value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isString(value: any): value is string {
  return value instanceof String || typeof value === "string";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDate(value: any) {
  return value instanceof Date;
}
