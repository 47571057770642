import type {FormInstance} from "antd";

import type {FormMeta} from "@/app/_components/form-builder";
import {Tap_Report_Category, TapReportStatus} from "@/shared/codegen/types";

export type CRUDRecord = {
  id?: string | number;
  title?: string;
  notes?: string | null;
  updatedAt?: Date;
};
interface TapMedia {
  __typename?: "TapMedia";
  storageKey: string;
}
interface TapBusinessOpeningHours {
  __typename?: "TapBusinessOpeningHours";
  openDay: number;
  openTime?: string | null;
  closeDay?: number | null;
  closeTime?: string | null;
}
interface TapBusiness {
  __typename?: "TapBusiness";
  id: string;
  name: string;
  address?: string | null | undefined;
  type?: string | null | undefined;
  openingHours: TapBusinessOpeningHours[];
}

interface TapUserProfilePicture {
  __typename?: "TapUserProfilePicture";
  storageKey: string;
}

interface TapUserProfile {
  __typename?: "TapUserProfile";
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  picture?: TapUserProfilePicture | null;
}

interface TapUser {
  __typename?: "TapUser";
  id: string;
  profile?: TapUserProfile | null;
}

interface TapReport {
  id: string;
  category: Tap_Report_Category;
  description?: string | undefined | null;
  createdAt: string;
  reportedByUser: TapUser;
}

export type PropertiesRecord = {
  id: string;
  tappedAt: string;
  comment?: string | null;
  rate: number;
  media: TapMedia[];
  business: TapBusiness;
  reportStatus: TapReportStatus;
  reportCount: number;
  lastReportDate?: string;
  tappedByUser: TapUser;
  reports: TapReport[];
};

export enum CRUDMode {
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export type CRUDInstance<F> = {
  mode: CRUDMode;
  form: FormInstance<F>;
};

export type CRUDFormMetaFunction<F> = (instance: CRUDInstance<F>) => FormMeta;
