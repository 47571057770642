import {List, Typography, Card, Space} from "antd";
import React from "react";

import "./reports.css"; // Asegúrate de tener los estilos necesarios
import {PropertiesRecord} from "../crud/types";

const {Title, Text} = Typography;

type PropertiesProps<R extends PropertiesRecord> = {
  record: R;
};

const Reports = <R extends PropertiesRecord>(props: React.PropsWithChildren<PropertiesProps<R>>) => {
  const {record} = props;
  function formatDate(dateString: string | number | Date | undefined) {
    if (dateString) {
      const date = new Date(dateString);
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = date.getUTCDate().toString().padStart(2, "0");
      const year = date.getUTCFullYear();

      return `${month}/${day}/${year}`;
    } else {
      return "";
    }
  }
  return (
    <div className="reports-container">
      <Title level={5}>({record.reports.length}) Reports</Title>
      <List
        itemLayout="vertical"
        dataSource={record.reports}
        renderItem={(item) => (
          <List.Item>
            <Card className="report-card">
              <Space direction="vertical">
                <Text strong>Category: {item.category}</Text>
                <Text type="secondary">Report date: {formatDate(item.createdAt)}</Text>
                {item.description && (
                  <>
                    <Text>Description:</Text>
                    <Text>{item.description}</Text>
                  </>
                )}
              </Space>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Reports;
