import {authorizedClientRequest} from "@/api/csr-request";
import {AppConfig} from "@/shared/app-config";
import {
  ApproveTapReportsDocument,
  MutationApproveTapReportsArgs,
  MutationRejectTapReportsArgs,
  QueryTapsArgs,
  RejectTapReportsDocument,
  TapsDocument,
} from "@/shared/codegen/types";

async function fetchTaps(variables: QueryTapsArgs = {}) {
  const response = await authorizedClientRequest({
    document: TapsDocument,
    variables,
  });
  if (response.taps) return response.taps;

  throw new Error("couldn't fetch taps");
}

export const getImageUriFromKey = (key: string, width: number, height: number) => {
  const imagesBaseUrl = AppConfig.Settings.Server.rest.imagesClient.baseUrl;

  return `${imagesBaseUrl}/${key}?d=${width}x${height}`;
};

async function approveReport(variables: MutationApproveTapReportsArgs) {
  const response = await authorizedClientRequest({
    document: ApproveTapReportsDocument,
    variables,
  });
  if (response.approveTapReports) return response.approveTapReports.tapId;

  throw new Error("couldn't approve report");
}

async function rejectReport(variables: MutationRejectTapReportsArgs) {
  const response = await authorizedClientRequest({
    document: RejectTapReportsDocument,
    variables,
  });
  if (response.rejectTapReports) return response.rejectTapReports.tapId;

  throw new Error("couldn't reject report");
}

export const TapModule = {
  fetchTaps,
  approveReport,
  rejectReport,
};
