"use client";

import {Button, Checkbox, Divider, Input, Radio, Space} from "antd";
import {CheckboxOptionType, CheckboxValueType} from "antd/es/checkbox/Group";
import type {FilterValue, ColumnType} from "antd/lib/table/interface";
import {CompareFn, SortOrder} from "antd/lib/table/interface";
import moment from "moment";
import React, {useRef} from "react";
import {createUseStyles} from "react-jss";

export type UseTableColumnFilteredInfo = Record<string, FilterValue | null>;

type UseTableFilterDataField<T> = keyof T | string | [keyof T, string] | [keyof T, string, string];
type UseTableFilterDataFields<T> = UseTableFilterDataField<T>[];

export type UseTableColumnOptions<T> = {
  dataIndex: keyof T | [keyof T] | [keyof T, string] | [keyof T, string, string];
  dataType: "string" | "date" | "code" | "boolean" | "number" | "custom";
  filter?: {
    dataFields?: UseTableFilterDataFields<T>;
    filteredInfo: UseTableColumnFilteredInfo;
    setFilteredInfo: (info: UseTableColumnFilteredInfo) => void;
    options?: CheckboxOptionType[];
    multiple?: boolean;
  };
  sort?: {
    defaultSortOrder?: SortOrder;
    sortDirections?: SortOrder[];
    sorter?:
      | boolean
      | CompareFn<T>
      | {
          compare?: CompareFn<T>;
          multiple?: number;
        };
  };
};

const useStyles = createUseStyles(({token}) => ({
  filterIcon: {},
  filterIconFiltered: {
    color: token.colorPrimary,
  },
  filterDropdownContainer: {
    padding: 8,
    minWidth: 150,
  },
  dropdownInput: {
    width: 200,
    marginBottom: 8,
    display: "block",
  },
  singleButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  multipleButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: 90,
  },
}));

export const useTableColumnProps = <T,>(options: UseTableColumnOptions<T>): ColumnType<T> => {
  const styles = useStyles();

  const {dataIndex, dataType, filter, sort} = options;

  const filteredValueKey = typeof dataIndex === "string" ? dataIndex : (dataIndex as string[]).join(".");
  const filteredValue = filter?.filteredInfo?.[filteredValueKey];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdownInputRef = useRef<any>(null);

  const defaultSorter = (a: T, b: T) => {
    const aValue = getFieldValue(dataIndex as UseTableFilterDataField<T>, a);
    const bValue = getFieldValue(dataIndex as UseTableFilterDataField<T>, b);

    switch (dataType) {
      case "string":
      case "date":
      case "code":
        return ((aValue ?? "") as string).localeCompare((bValue ?? "") as string);
      case "number":
        return ((aValue as number) ?? 0) - ((bValue as number) ?? 0);
      case "boolean":
        return ((bValue as number) ?? 0) - ((aValue as number) ?? 0);
      default:
        throw new Error(`Default sorter for type '${dataType}' not implemented yet.`);
    }
  };

  let dataTypeProps: ColumnType<T> = {};

  const formatTimestamp = (value: string) => {
    return moment(value).format("MMMM DD, YYYY");
  };

  switch (dataType) {
    case "string":
      dataTypeProps = {
        ellipsis: true,
      };
      break;
    case "date":
      dataTypeProps = {
        ellipsis: true,
        align: "center",
        width: 100,
        render: (value) => formatTimestamp(value),
      };
      break;
    case "code":
    case "boolean":
      dataTypeProps = {
        ellipsis: true,
        align: "center",
      };
      break;
    case "number":
      dataTypeProps = {
        ellipsis: true,
        align: "right",
      };
      break;
    default:
      break;
  }

  const getFieldValue = (field: UseTableFilterDataField<T>, record: T): unknown | undefined => {
    if (typeof field === "string") {
      const {[field]: value} = record;
      return value;
    } else if (Array.isArray(field)) {
      switch ((field as string[]).length) {
        case 1: {
          const {[field[0] as never]: value} = record;
          return value;
        }
        case 2: {
          const {[field[0] as never]: object} = record;
          if (object) {
            const {[field[1] as never]: value} = object;
            return value;
          }
          break;
        }
        case 3: {
          const {[field[0] as never]: object1} = record;
          if (object1) {
            const {[field[1] as never]: object2} = object1;
            if (object2) {
              const {[field[2] as never]: value} = object2;
              return value;
            }
          }
          break;
        }
        default:
          break;
      }
    }

    return undefined;
  };

  const handleApplyFilter = (selectedKeys: string[], confirm: () => void) => {
    confirm();
    filter?.setFilteredInfo?.({...filter?.filteredInfo, [filteredValueKey]: selectedKeys});
  };

  const handleClearFilter = (clearFilters: (() => void) | undefined, confirm: () => void) => {
    clearFilters?.();
    filter?.setFilteredInfo?.({...filter?.filteredInfo, [filteredValueKey]: null});
    confirm();
  };

  return {
    ...dataTypeProps,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataIndex: dataIndex as UseTableFilterDataField<T>,
    // Filter
    ...(options.filter && {
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
        const isApplyButtonVisible = !options.filter!.options || options.filter!.multiple;

        return (
          <div className={styles.filterDropdownContainer}>
            {options.filter!.options && options.filter!.multiple ? (
              <>
                <Checkbox.Group
                  style={{display: "flex", flexDirection: "column"}}
                  options={options.filter!.options}
                  value={selectedKeys as CheckboxValueType[]}
                  onChange={(values) => setSelectedKeys(values as string[])}
                />
                <Divider style={{marginTop: 10, marginBottom: 10}} />
              </>
            ) : options.filter!.options && !options.filter!.multiple ? (
              <>
                <Radio.Group
                  style={{display: "flex", flexDirection: "column"}}
                  options={options.filter!.options}
                  value={selectedKeys.length > 0 ? selectedKeys[0] : undefined}
                  onChange={(event) => {
                    const value = event.target.value;
                    setSelectedKeys([value]);
                    handleApplyFilter([value], confirm);
                  }}
                />
                <Divider style={{marginTop: 10, marginBottom: 10}} />
              </>
            ) : (
              <Input
                ref={dropdownInputRef}
                className={styles.dropdownInput}
                value={selectedKeys.length > 0 ? selectedKeys[0] : undefined}
                placeholder="Filter"
                onChange={(event) => setSelectedKeys(event.target.value ? [event.target.value] : [])}
                onPressEnter={() => handleApplyFilter(selectedKeys as string[], confirm)}
              />
            )}

            <Space className={isApplyButtonVisible ? styles.multipleButtonContainer : styles.singleButtonContainer}>
              <Button className={styles.button} size="small" onClick={() => handleClearFilter(clearFilters, confirm)}>
                Clear
              </Button>
              {isApplyButtonVisible && (
                <Button
                  className={styles.button}
                  size="small"
                  type="primary"
                  onClick={() => handleApplyFilter(selectedKeys as string[], confirm)}>
                  Apply
                </Button>
              )}
            </Space>
          </div>
        );
      },
      onFilterDropdownOpenChange: (open) => {
        if (open) {
          setTimeout(() => dropdownInputRef.current?.select(), 200);
        }
      },
      onFilter: (filterValue, record) => {
        const filterFields = filter?.dataFields ?? ([dataIndex] as UseTableFilterDataFields<T>);

        for (const filterField of filterFields) {
          const fieldValue = getFieldValue(filterField, record);
          let fieldStringValue = "";

          switch (typeof fieldValue) {
            case "string":
              fieldStringValue = fieldValue.toLocaleLowerCase();
              break;
            case "number":
              fieldStringValue = fieldValue.toString().toLocaleLowerCase();
              break;
            case "boolean":
              fieldStringValue = fieldValue ? "true" : "false";
              break;
            default:
              break;
          }

          let match = false;

          switch (typeof filterValue) {
            case "string":
              match = filterValue
                .split(",")
                .some((filterValuePart) => fieldStringValue?.includes(filterValuePart.trim().toLocaleLowerCase()));
              break;
            case "number":
              match = fieldStringValue?.includes(filterValue.toString());
              break;
            case "boolean":
              match = fieldStringValue === (filterValue ? "true" : "false");
              break;
            default:
              break;
          }

          if (match) return match;
        }

        return false;
      },
      filteredValue: filteredValue,
    }),
    // Sort
    ...(options.sort && {
      sorter: sort?.sorter ?? defaultSorter,
      sortDirections: sort?.sortDirections ?? ["ascend", "descend", "ascend"],
      defaultSortOrder: sort?.defaultSortOrder,
    }),
  };
};
