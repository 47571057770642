import {EnvironmentOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Row, Col, Typography, Space, Carousel, Image} from "antd";
import React from "react";

import "./properties.css"; // Asegúrate de tener los estilos necesarios
import {SadFace} from "../../../assets/icons/SadFace";
import {PropertiesRecord} from "../crud/types";
import {getImageUriFromKey} from "@/modules/tap";

const {Title, Text} = Typography;

type PropertiesProps<R extends PropertiesRecord> = {
  record: R;
};

const Properties = <R extends PropertiesRecord>(props: React.PropsWithChildren<PropertiesProps<R>>) => {
  const {record} = props;
  const slidesToShow = record.media?.length && record.media.length < 3 ? record.media.length : 2.5;
  function formatDate(dateString: string | number | Date | undefined) {
    if (dateString) {
      const date = new Date(dateString);
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      const day = date.getUTCDate().toString().padStart(2, "0");
      const year = date.getUTCFullYear();

      return `${month}/${day}/${year}`;
    } else {
      return "";
    }
  }
  return (
    <div className="properties-container">
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4}>{record.business?.name}</Title>
          <Text type="secondary">Restaurant</Text>
          <br />
        </Col>
        <Col>
          <Space direction="horizontal" align="end" style={{alignItems: "center"}}>
            <Text type="secondary">{formatDate(record.lastReportDate)}</Text>
            <SadFace />
          </Space>
        </Col>
      </Row>
      <Space direction="vertical" size="small" style={{marginBottom: "15px"}}>
        <div>
          <EnvironmentOutlined />
          <Text>{record.business?.address}</Text>
        </div>
      </Space>
      <Carousel
        dots={false}
        slidesToShow={slidesToShow}
        infinite={false}
        arrows
        style={{width: slidesToShow < 2 ? "50%" : "100%", height: "auto"}}>
        {record.media?.map((media, index) => (
          <div className="carousel-item" key={index}>
            <Image alt="chef" src={getImageUriFromKey(media.storageKey || "", 700, 700)} className="carousel-image" />
          </div>
        ))}
      </Carousel>
      <Row style={{marginTop: "16px"}}>
        <Text style={{color: "#726F6F"}}>{record.comment}</Text>
      </Row>
      <Row align="middle" style={{marginTop: "16px"}}>
        {record.tappedByUser.profile?.picture?.storageKey ? (
          <>
            <Avatar
              src={getImageUriFromKey(record.tappedByUser.profile?.picture?.storageKey || "", 56, 56)}
              size={60}
            />
            <div style={{marginLeft: "8px"}}>
              <Space direction="vertical" size={0}>
                <Text>{record.tappedByUser.profile?.username}</Text>
                <Text type="secondary">
                  {record.tappedByUser.profile?.firstName} {record.tappedByUser.profile?.lastName}
                </Text>
              </Space>
            </div>
          </>
        ) : (
          <>
            <Avatar icon={<UserOutlined />} size={60} />
            <div style={{marginLeft: "8px"}}>
              <Space direction="vertical" size={0}>
                <Text>{record.tappedByUser.profile?.username}</Text>
                <Text type="secondary">
                  {record.tappedByUser.profile?.firstName} {record.tappedByUser.profile?.lastName}
                </Text>
              </Space>
            </div>
          </>
        )}
      </Row>
    </div>
  );
};

export default Properties;
