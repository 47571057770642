"use client";

import {UseQueryResult} from "@tanstack/react-query";
import {Alert, Button, Tag} from "antd";
import {SorterResult} from "antd/es/table/interface";
import type {ColumnsType} from "antd/lib/table";
import React, {useMemo, useState} from "react";
import {createUseStyles} from "react-jss";

import {FormMeta} from "../form-builder";
import Table from "../table";
import {useCRUD} from "@/app/_components/crud/context";
import {CRUDMode, PropertiesRecord} from "@/app/_components/crud/types";
import EmptyTable from "@/app/_components/empty-table";
import PageHeader from "@/app/_components/page-header";
import {UseTableColumnFilteredInfo} from "@/hooks/use-table-column-props";
import {ConnectionOrderDirection, TapReportStatus} from "@/shared/codegen/types";
import {isEmpty} from "@/shared/utils/object";

import "./filterComponent.css";
import "./table.css";
import CRUDDetails from "./details";

export type RecordsWithTotalCount<R> = {edges: {node: R}[]; totalCount: number};
type FilterType = TapReportStatus.Pending | TapReportStatus.Approved | TapReportStatus.Rejected;

type CRUDPageProps<R> = {
  identifier: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  table: {
    columns: ColumnsType<R>;
  };
  queryResult: UseQueryResult<RecordsWithTotalCount<R>>;
  onInvalidateCache: () => Promise<void>;
  filter: FilterType | null;
  setFilter: React.Dispatch<React.SetStateAction<FilterType | null>>;
  approveReport: (id: string) => Promise<string>;
  rejectReport: (id: string) => Promise<string>;
};

const useStyles = createUseStyles({
  filteredTag: {
    cursor: "pointer",
  },
  searchInput: {
    width: 301,
  },
});

const DefaultPage = <R extends PropertiesRecord>(props: React.PropsWithChildren<CRUDPageProps<R>>) => {
  const styles = useStyles();
  const {
    identifier,
    title,
    subtitle,
    table,
    queryResult,
    filter,
    setFilter,
    approveReport,
    rejectReport,
    onInvalidateCache,
  } = props;

  const {
    setInputSearchText,
    searchText,
    filteredInfo,
    selection,
    setFilteredInfo,
    setSelection,
    setRecordsOffset,
    setTablePageSize,
    tablePageSize,
    setOrderBy,
  } = useCRUD<R>();

  const {data: dataRecords, error, isLoading} = queryResult;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage = error?.message as string;
  const isFiltered = !isEmpty(searchText) || Object.values(filteredInfo).some((value) => value != null);

  const [detailsIsOpen, setDetailsIsOpen] = useState(false);

  const handleClearFilters = () => {
    const filteredInfoEmpty: UseTableColumnFilteredInfo = {};
    Object.keys(filteredInfo).forEach((key) => (filteredInfoEmpty[key] = null));
    setFilteredInfo(filteredInfoEmpty);

    setInputSearchText(undefined);
  };

  const handleRowClick = (record: R) => {
    setSelection(record);
    setDetailsIsOpen(true);
  };

  const handleTablePropsChange = (sorter: SorterResult<R> | SorterResult<R>[]) => {
    if ("field" in sorter && sorter.field) {
      let sorterField: string;

      // sorter.field (dataIndex) could be either a string or a string array
      if (typeof sorter.field == "string") sorterField = sorter.field;
      else if (Array.isArray(sorter.field)) sorterField = sorter.field[0];
      else return;
      if (Array.isArray(sorter.field)) {
        if (sorter.field[0] === "business") {
          sorterField = sorter.field[0].toUpperCase() + "_" + sorter.field[1].toUpperCase();
        } else {
          sorterField = "REPORTED_BY_" + sorter.field[2].toUpperCase();
        }
      } else if (typeof sorter.field == "string") {
        if (sorter.field.startsWith("report")) {
          const words = sorter.field.split(/(?=[A-Z])/);
          const upperCaseWords = words.map((word) => word.toUpperCase());
          sorterField = upperCaseWords.join("_");
        } else {
          sorterField = "LAST_REPORTED_DATE";
        }
      }
      setOrderBy({
        /*
        order connection fields are the corresponding uppercase of each column name in the server, for this
        to work with no additional config, table dataIndex must match a lowercase or camelCase version of <R>OrderField
        */
        field: sorterField,
        direction: sorter.order == "descend" ? ConnectionOrderDirection.Desc : ConnectionOrderDirection.Asc,
      });
    }
  };

  const dataSource = useMemo(() => dataRecords?.edges.map((e: {node: R}) => e.node), [dataRecords?.edges]);

  const handleFilterChange = (type: FilterType) => {
    if (filter === type) {
      setFilter(null);
    } else {
      setFilter(type);
    }
    setRecordsOffset(0);
  };
  const formMeta = (): FormMeta => {
    return {
      initialValues: {},
      fields: [
        {
          key: "name",
          label: "Name",
          required: true,
          colSpan: 2,
          clear: "both",
        },
        {
          key: "code",
          label: "Code",
          required: true,
          rules: [{len: 2}],
          colSpan: 2,
          clear: "both",
        },
      ],
    };
  };

  return (
    <>
      <PageHeader
        title={title}
        subtitle={subtitle}
        tags={
          isFiltered ? (
            <Tag className={styles.filteredTag} closable onClose={handleClearFilters} onClick={handleClearFilters}>
              Filtered
            </Tag>
          ) : undefined
        }
        center={<></>}
        extra={<></>}>
        <div className="filter-container">
          <span style={{fontSize: "16px"}}>Filter results by:</span>
          <Button
            className={`filter-button pending-button ${filter === TapReportStatus.Pending ? "active" : ""}`}
            onClick={() => handleFilterChange(TapReportStatus.Pending)}>
            Pending
          </Button>
          <Button
            className={`filter-button approved-button ${filter === TapReportStatus.Approved ? "active" : ""}`}
            onClick={() => handleFilterChange(TapReportStatus.Approved)}>
            Approved
          </Button>
          <Button
            className={`filter-button rejected-button ${filter === TapReportStatus.Rejected ? "active" : ""}`}
            onClick={() => handleFilterChange(TapReportStatus.Rejected)}>
            Rejected
          </Button>
        </div>
        {errorMessage && <Alert type="error" showIcon message="Failed to load" description={errorMessage} />}

        <Table<R>
          identifier={identifier}
          totalItems={dataRecords?.totalCount}
          handleRowClick={handleRowClick}
          setRecordsOffset={setRecordsOffset}
          tablePageSize={tablePageSize}
          setTablePageSize={setTablePageSize}
          columns={table.columns}
          dataSource={dataSource}
          loading={isLoading}
          locale={{emptyText: <EmptyTable loading={isLoading} />}}
          onChange={(_, __, sorter) => handleTablePropsChange(sorter)}
        />
      </PageHeader>

      {detailsIsOpen && (
        <CRUDDetails<R, R>
          defaultMode={CRUDMode.Read}
          record={selection}
          open={detailsIsOpen}
          form={{
            meta: formMeta,
          }}
          onUpdate={function (): Promise<R> {
            throw new Error("Function not implemented.");
          }}
          onClose={() => {
            setDetailsIsOpen(false);
          }}
          approveReport={approveReport}
          rejectReport={rejectReport}
          onInvalidateCache={onInvalidateCache}
        />
      )}
    </>
  );
};

export default DefaultPage;
