"use client";

import {CloseOutlined} from "@ant-design/icons";
import {Button, Drawer, Modal, Space, Spin, Tabs, Typography} from "antd";
import React, {Fragment, Suspense, useCallback, useMemo, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {PropertiesRecord} from "../crud/types";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import {TapReportStatus} from "@/shared/codegen/types";
import {Breakpoints} from "@/utils/breakpoints";

import Properties from "./properties";
import Reports from "./reports";
import {CRUDMode, CRUDFormMetaFunction} from "./types";

import "./modal.css";

type CRUDDetailsProps<R extends PropertiesRecord, F> = {
  defaultMode: CRUDMode;
  record: R;
  open: boolean;
  form: {
    meta: CRUDFormMetaFunction<F>;
  };
  options?: {
    actions?: {
      create?: boolean;
      update?: boolean;
      delete?: boolean;
    };
    icons?: {
      default?: (className?: string) => React.ReactNode;
    };
    labels?: {
      createTitle?: string;
      createSubtitle?: string;
      updateTitle?: string;
      updateSubtitle?: string;
    };
    modal?: {
      type?: "auto" | "drawer" | "modal";
    };
    tabs?: {
      history?: {
        enabled?: boolean;
      };
      items?: {
        key: string;
        label: string;
        children: React.ReactNode;
      }[];
    };
  };
  onUpdate: (record: R, values: F) => Promise<R>;
  onClose: () => void;
  approveReport?: (id: string) => Promise<string>;
  rejectReport?: (id: string) => Promise<string>;
  onInvalidateCache: () => Promise<void>;
};

const useStyles = createUseStyles({
  titleIcon: {
    fontSize: 30,
  },
  title: {
    fontSize: 18,
  },
  subtitle: {
    fontSize: 14,
    width: 590,
  },
  dropdown: {
    width: 180,
  },
  tabs: {
    width: "100%",
  },
  "@media (max-width: 575px)": {
    tabs: {
      "&&": {
        "& .ant-tabs-tab": {
          fontSize: 12,
          padding: "8px 0px 8px 8px !important",
        },
        "& .ant-space-item": {
          fontSize: 12,
        },
        "& .ant-typography": {
          fontSize: 12,
        },
        "& .ant-statistic-content": {
          fontSize: 14,
        },
        "& .ant-statistic-title": {
          fontSize: 12,
        },
        "& .ant-descriptions-item-label": {
          fontSize: 10,
          padding: 8,
        },
        "& .ant-descriptions-item-content": {
          fontSize: 10,
          padding: 8,
        },
        "& .ant-tabs-content-holder": {
          overflowY: "auto",
        },
        "& .ant-descriptions-view": {
          overflowY: "auto",
        },
      },
    },
  },
  "@media (min-width: 576px) AND (max-width: 756px)": {
    tabs: {
      "&&": {
        "& .ant-descriptions-item-label": {
          fontSize: 12,
          padding: 8,
        },
        "& .ant-descriptions-item-content": {
          fontSize: 12,
          padding: 8,
        },
      },
    },
  },
});

const CRUDDetails = <R extends PropertiesRecord, F>(props: React.PropsWithChildren<CRUDDetailsProps<R, F>>) => {
  const styles = useStyles();
  const theme = useTheme();
  const {record, open, options, approveReport, rejectReport, onInvalidateCache} = props;

  const {width: windowWidth} = useWindowDimensions();
  const isWindowSmall = windowWidth <= Breakpoints.SM;

  const [activeTab, setActiveTab] = useState<string>(`_reported`);

  const title = "Reported Tap";
  const subtitle: string | undefined = undefined;

  const handleTabChange = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);

  const handleCloseClick = useCallback(async () => {
    props.onClose();
  }, [props]);

  const tabsItems = useMemo(
    () => [
      {
        label: "Reported Tap",
        key: `_reported`,
        children: (
          <Suspense fallback={<Spin size="small" />}>
            <Properties record={record} />
          </Suspense>
        ),
      },
      {
        label: "List of Reports",
        key: `_list`,
        children: <Reports record={record} />,
      },
    ],
    [record]
  );

  const titleNode = useMemo(() => {
    const extraIconStyle = {cursor: "pointer", color: theme.isDark ? "#ffffffd9" : undefined};
    return (
      <Space style={{alignItems: "center"}}>
        <CloseOutlined style={extraIconStyle} onClick={handleCloseClick} />
        <Space direction="vertical" size={0}>
          <Typography.Text className={styles.title}>{title}</Typography.Text>
          {subtitle && (
            <Typography.Text className={styles.subtitle} type="secondary" ellipsis>
              {subtitle}
            </Typography.Text>
          )}
        </Space>
      </Space>
    );
  }, [subtitle, title, styles, handleCloseClick, theme.isDark]);

  const handleApprove = async (id: string) => {
    if (approveReport) {
      await approveReport(id);
      await onInvalidateCache();
      props.onClose();
    }
  };

  const handleReject = async (id: string) => {
    if (rejectReport) {
      await rejectReport(id);
      await onInvalidateCache();
      props.onClose();
    }
  };

  const content = useMemo(
    () => (
      <Fragment key={record?.id}>
        <Tabs
          activeKey={activeTab}
          animated={false}
          className={styles.tabs}
          items={tabsItems}
          onChange={handleTabChange}
          size={isWindowSmall ? undefined : "middle"}></Tabs>
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: "16px"}}>
          <Space direction="horizontal" size={20}>
            {approveReport && record.reportStatus === TapReportStatus.Pending && (
              <Button
                className="approve-button"
                style={{color: "white", backgroundColor: "#3057D1", width: "180px", fontWeight: "300"}}
                onClick={() => {
                  handleApprove(record.id);
                }}>
                Approve Report
              </Button>
            )}
            {rejectReport && record.reportStatus === TapReportStatus.Pending && (
              <Button
                style={{color: "#3057D1", borderColor: "#3057D1", width: "180px", fontWeight: "300"}}
                onClick={() => {
                  handleReject(record.id);
                }}>
                Reject Report
              </Button>
            )}
          </Space>
        </div>
      </Fragment>
    ),
    [activeTab, record?.id, tabsItems, styles, isWindowSmall, handleTabChange]
  );

  const showModal = (options?.modal?.type ?? "auto") === "auto" ? isWindowSmall : options?.modal?.type === "modal";

  return useMemo(
    () =>
      showModal ? (
        <Modal
          bodyStyle={{}}
          closable={false}
          maskClosable={true}
          keyboard={true}
          mask={false}
          open={open}
          title={<Space style={{display: "flex", justifyContent: "space-between"}}>{titleNode}</Space>}
          footer={false}
          onCancel={handleCloseClick}>
          {content}
        </Modal>
      ) : (
        <Drawer
          headerStyle={{paddingTop: 14, paddingBottom: 10}}
          closable={false}
          maskClosable={true}
          keyboard={true}
          mask={true}
          open={open}
          placement="right"
          size="large"
          title={titleNode}
          onClose={() => props.onClose()}>
          {content}
        </Drawer>
      ),
    [showModal, open, titleNode, content, handleCloseClick]
  );
};

export default CRUDDetails;
